export async function awaitNewAnimationFrame(): Promise<void> {
  // await sleep(29)
  return new Promise((resolve) => {
    requestAnimationFrame(() => {
      resolve()
    })
  })
}

export async function sleep(duration: number): Promise<void> {
  return new Promise((resolve) => {
    setInterval(resolve, duration)
  })
}

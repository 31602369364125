import { random, sample } from './lib/math'
import { shuffle } from './lib/random'
import { RGB } from './types'

// export const _palette: RGB[] = [
//   // [0, 200, 0],
//   [255, 0, 0],
//   [0, 0, 255],
//   [255, 255, 255],
//   // [0, 0, 128],
//   // [252, 2, 160],
//   [0, 0, 0],
//   [255, 255, 0]
//   // [252, 172, 1],
//   // [0, 216, 254]
// ]

// export const palette: RGB[] = [
//   [228, 127, 61],
//   [80, 130, 65],
//   [124, 45, 29],
//   [224, 79, 58],
//   [27, 70, 168],
//   [130, 174, 249],
//   [168, 54, 47],
//   [235, 74, 104],
//   [0, 0, 0]
// ]

const fullPalette: RGB[] = [
  [0, 0, 0],
  [255, 255, 255],
  [250, 10, 10],
  [10, 10, 250],
  // [237, 96, 52],
  [132, 192, 230],
  [252, 241, 98],
  // [180, 57, 43],
  // [112, 124, 143],
  [15, 34, 150],
  [210, 100, 63],
  [87, 148, 93],
  [227, 144, 161],
  [228, 234, 76],
  [150, 173, 138],
  // [202, 84, 61],
  // [137, 153, 130],
  // [210, 201, 153],
  // [196, 194, 189],
  // [23, 53, 178],
  // [29, 64, 203]
]

let palette = samplePalette(fullPalette)

export function getCurrentPalette(): RGB[] {
  return palette
}

function createRandomOrderPalette(input: RGB[]): RGB[] {
  const result: RGB[] = []
  for (let i = 0; i < 10; i++) {
    result.push(...shuffle(input))
  }
  return result
}

export function samplePalette(input: RGB[]): RGB[] {
  const selection: RGB[] = []
  const result: RGB[] = []
  const size = random(3) + 1
  console.log(size, 'colours')
  for (let i = 0; i < size; i++) {
    selection.push(sample(input) as RGB)
  }
  if (Math.random() > 0.5) {
    return selection
  }
  for (let i = 0; i < 100; i++) {
    result.push(sample(selection)!)
  }
  return result
}

export function resetPalette(): void {
  palette = samplePalette(fullPalette)
}

import Artwork from './components/Artwork'
import { useState } from 'react'
import { getRandomRules } from './artwork/lib/rules'

import { useInterval } from 'usehooks-ts'

export default function UI() {
  const [rules, setRules] = useState(getRandomRules())

  useInterval(() => {
    setRules(getRandomRules())
  }, 20000)
  return (
    <div className='container'>
      <div className='artwork-container'>
        <Artwork rules={rules} />
      </div>
    </div>
  )
}

// function getNextFrequency(oldValue: number): number {
//   switch (oldValue) {
//     case 0:
//       return 1
//     case 1:
//       return 3
//     case 3:
//       return 5
//     case 5:
//       return 10
//     case 10:
//       return 100
//     case 100:
//       return 0
//     default:
//       return 0
//   }
// }

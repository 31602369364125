import {
  addGroupNumbers,
  createGrid,
  drawCells,
  setDimensions,
  updateAllCellsState
} from '../artwork/artwork'
import { awaitNewAnimationFrame, sleep } from '../artwork/lib/anim'
import { viewport } from '../artwork/lib/viewport'
import { Cell, CellState, Rule } from '../artwork/types'
import { useEffect, useRef, useState } from 'react'
import Stats from 'stats.js.fps'
import { resetPalette } from '../artwork/palette'

export const stats = new Stats()

if (window.location.hash === '#debug') {
  stats.showPanel(0)
  document.body.appendChild(stats.dom)
}

interface Props {
  rules: Rule[]
}

let grid: Cell[] = []
let maxUpdateIndex = 0
let canvas: HTMLCanvasElement

window.addEventListener('resize', () => {
  window.location.href = window.location.href
})

export default function Artwork({ rules }: Props) {
  const container = useRef<HTMLDivElement>(null)
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (Math.random() > 0.5) {
      resetPalette()
    }
    grid = createGrid(rules, (Math.random() * 0xfffffff) << 0)
    grid = addGroupNumbers(grid)
    maxUpdateIndex = 0
  }, [rules])

  function setCanvasDimensions() {
    const vp = viewport()
    canvas.width = vp[0] * window.devicePixelRatio
    canvas.height = vp[1] * window.devicePixelRatio
    canvas.style.width = `${vp[0]}px`
    canvas.style.height = `${vp[1]}px`
    canvas.style.position = 'fixed'
    canvas.style.left = '0px'
    canvas.style.top = '0px'
  }

  useEffect(() => {
    if (!container.current || initialized || !grid) return
    // const fps = await calculateFPS()
    // console.log('fps', fps)
    setInitialized(true)

    canvas = document.createElement('canvas')
    setCanvasDimensions()
    const ctx = canvas.getContext('2d')!

    const cellsState: CellState[] = []
    container.current.appendChild(canvas)
    setDimensions()
    for (let i = 0; i < grid.length; i++) {
      cellsState[i] = {
        alpha: 0,
        angle: 0,
        color: [0, 0, 0],
        completed: false
      }
    }

    function draw() {


      updateAllCellsState(grid!, cellsState, maxUpdateIndex)
      if (maxUpdateIndex < cellsState.length) {
        maxUpdateIndex += 5 / speedRatio()
      }
      drawCells(ctx, grid!, cellsState)
    }

    async function theLoop(): Promise<void> {
      ctx.strokeStyle = '#000000'

      let introCompleted = false
      setTimeout(() => {
        introCompleted = true
      }, 2000)
      while (true) {
        stats.begin()
        ctx.fillStyle = 'rgb(127,127,127)'
        ctx.globalCompositeOperation = 'source-over'
        ctx.fillRect(0, 0, canvas.width, canvas.height)
        if (introCompleted) draw()
        await awaitNewAnimationFrame()
        stats.end()
      }
    }

    console.log('the loop')
    theLoop()

  }, [container, initialized])



  return (
    <div
      ref={container}
      style={{
        boxSizing: 'border-box',
        background: 'white',
        padding: '10px'
      }}
    />
  )
}

const DEFAULT_FPS = 30
export function fps(): number {
  return Math.round(stats.fps) || DEFAULT_FPS
}

export function speedRatio(): number {
  return fps() / DEFAULT_FPS
}

import { Rule } from '../types'

export const PRESET_RULES: Rule[][] = [
  [
    { pattern: [null, null, null, null], chances: [1, 3, 0, 3, 0, 0] },
    { pattern: [1, null, null, null], chances: [0, 0, 1, 0, 0, 0] },
    { pattern: [3, null, null, null], chances: [0, 0, 0, 0, 1, 0] },
    { pattern: [0, 2, null, null], chances: [1, 2, 0, 0, 0, 0] },
    { pattern: [2, 2, null, null], chances: [1, 2, 0, 0, 0, 0] },
    { pattern: [0, 1, 2, null], chances: [1, 2, 0, 0, 0, 0] },
    { pattern: [2, 1, 2, null], chances: [1, 2, 0, 0, 0, 0] },
    { pattern: [4, 1, 2, null], chances: [1, 2, 0, 0, 0, 0] },
    { pattern: [2, null, 1, null], chances: [0, 1, 0, 0, 0, 0] },
    { pattern: [0, null, 1, null], chances: [0, 1, 0, 0, 0, 0] }
  ],
  [
    { pattern: [null, null, null, null], chances: [0, 1, 1, 1, 1, 0] },
    { pattern: [null, 1, null, null], chances: [0, 0, 0, 1, 0, 0] },
    { pattern: [null, 2, null, null], chances: [0, 9, 0, 0, 1, 0] },
    { pattern: [3, null, null, null], chances: [0, 0, 0, 0, 1, 0] },
    { pattern: [null, null, null, null], chances: [0, 1, 0, 0, 0, 0] }
  ],
  [
    { pattern: [null, null, null, null], chances: [0, 1, 1, 1, 1, 0] },
    { pattern: [null, 1, null, null], chances: [0, 0, 0, 1, 0, 0] },
    { pattern: [null, 2, null, null], chances: [0, 9, 0, 0, 1, 0] },
    { pattern: [3, null, null, null], chances: [0, 0, 0, 0, 1, 0] },
    { pattern: [1, null, null, null], chances: [0, 1, 0, 0, 0, 0] }
  ],
  [
    { pattern: [null, null, null, null], chances: [0, 1, 1, 1, 1, 0] },
    { pattern: [null, 1, null, null], chances: [0, 0, 0, 1, 0, 0] },
    { pattern: [null, 2, null, null], chances: [0, 9, 0, 0, 1, 0] },
    { pattern: [3, null, null, null], chances: [0, 0, 0, 0, 1, 0] },
    { pattern: [1, null, null, null], chances: [0, 0, 1, 0, 0, 0] }
  ],
  [{ pattern: [null, null, null, null], chances: [0, 1, 6, 1, 7, 0] }],
  [
    { pattern: [null, null, null, null], chances: [0, 1, 0, 1, 0, 0] },
    { pattern: [1, null, null, null], chances: [0, 0, 1, 0, 0, 0] },
    { pattern: [3, null, null, null], chances: [0, 0, 0, 0, 1, 0] }
  ],
  [
    { pattern: [null, null, null, null], chances: [1, 4, 0, 4, 0, 0] },
    { pattern: [1, null, null, null], chances: [0, 0, 1, 0, 0, 0] },
    { pattern: [3, null, null, null], chances: [0, 0, 0, 0, 1, 0] }
  ],
  [
    { pattern: [null, null, null, null], chances: [1, 4, 0, 4, 0, 0] },
    { pattern: [1, null, null, null], chances: [0, 0, 1, 0, 0, 0] },
    { pattern: [3, null, null, null], chances: [0, 0, 0, 0, 1, 0] },
    { pattern: [null, null, 1, null], chances: [1, 9, 0, 0, 0, 0] }
  ],
  [
    { pattern: [null, null, null, null], chances: [0, 4, 0, 4, 0, 0] },
    { pattern: [null, 1, null, null], chances: [0, 0, 0, 1, 0, 0] },
    { pattern: [null, 3, null, null], chances: [0, 1, 0, 0, 0, 0] }
  ],
  [{ pattern: [null, null, null, null], chances: [0, 6, 0, 7, 0, 0] }],
  [
    { pattern: [null, null, null, null], chances: [1, 2, 0, 2, 0, 0] },
    { pattern: [1, null, null, null], chances: [0, 0, 1, 0, 0, 0] },
    { pattern: [3, null, null, null], chances: [0, 0, 0, 0, 1, 0] },
    { pattern: [0, 1, 2, null], chances: [1, 1, 0, 0, 0, 0] },
    { pattern: [2, 1, 2, null], chances: [0, 1, 0, 0, 0, 0] },
    { pattern: [null, null, 1, null], chances: [0, 1, 0, 0, 0, 0] }
  ],
  [
    { pattern: [null, null, null, null], chances: [0, 4, 4, 1, 1, 0] },
    { pattern: [null, null, null, null], chances: [0, 0, 4, 1, 0, 0] }
  ],
  [
    { pattern: [null, null, null, null], chances: [0, 1, 0, 0, 4, 0] },
    { pattern: [null, null, 1, null], chances: [0, 1, 0, 0, 0, 0] }
  ],
  [
    { pattern: [null, null, null, null], chances: [0, 1, 0, 0, 0, 0] },
    { pattern: [null, null, 1, null], chances: [0, 0, 1, 0, 0, 0] }
  ],
  [
    { pattern: [null, null, null, null], chances: [0, 1, 0, 0, 0, 0] },
    { pattern: [null, null, 1, null], chances: [0, 0, 1, 0, 0, 0] },
    { pattern: [1, null, null, null], chances: [0, 1, 0, 0, 0, 0] }
  ],
  [
    { pattern: [null, null, null, null], chances: [0, 1, 0, 0, 0, 0] },
    { pattern: [null, null, 1, null], chances: [0, 0, 1, 0, 0, 0] },
    { pattern: [1, null, null, null], chances: [0, 1, 0, 5, 0, 0] }
  ],
  [
    { pattern: [null, null, null, null], chances: [0, 1, 0, 0, 0, 0] },
    { pattern: [null, null, 1, null], chances: [0, 0, 1, 0, 0, 0] },
    { pattern: [1, null, null, null], chances: [0, 1, 0, 5, 0, 0] },
    { pattern: [2, null, null, null], chances: [0, 1, 0, 5, 0, 0] }
  ],
  [
    { pattern: [null, null, null, null], chances: [0, 0, 1, 0, 4, 0] },
    { pattern: [2, null, null, null], chances: [0, 4, 1, 0, 0, 0] },
    { pattern: [null, 2, 3, 4], chances: [0, 0, 0, 4, 0, 0] },
    { pattern: [null, null, 1, null], chances: [0, 1, 1, 1, 0, 0] }
  ],
  [
    { pattern: [null, null, null, null], chances: [1, 0, 3, 0, 0, 0] },
    { pattern: [0, null, null, null], chances: [0, 1, 0, 0, 0, 0] },
    { pattern: [null, 1, null, null], chances: [0, 0, 0, 0, 1, 0] },
    { pattern: [null, null, null, null], chances: [0, 1, 0, 0, 0, 0] }
  ],
  [
    { pattern: [null, null, null, null], chances: [1, 0, 8, 0, 0, 0] },
    { pattern: [0, null, null, null], chances: [0, 1, 0, 0, 0, 0] },
    { pattern: [null, 1, null, null], chances: [0, 0, 0, 0, 1, 0] },
    { pattern: [null, 2, null, null], chances: [0, 0, 0, 0, 1, 0] },
    { pattern: [null, 1, 2, null], chances: [0, 0, 0, 1, 0, 0] }
  ],
  [
    { pattern: [null, null, null, null], chances: [0, 4, 4, 0, 4, 0] },
    { pattern: [null, null, null, null], chances: [0, 4, 0, 1, 1, 0] },
    { pattern: [null, null, null, 3], chances: [0, 4, 1, 0, 4, 0] },
    { pattern: [null, null, 2, null], chances: [0, 0, 4, 0, 4, 0] }
  ],
  [
    { pattern: [null, null, null, null], chances: [0, 1, 0, 5, 0, 0] },
    { pattern: [1, 3, null, null], chances: [0, 1, 3, 0, 0, 0] },
    { pattern: [3, null, null, null], chances: [0, 1, 0, 0, 0, 0] }
  ],
  [
    { pattern: [null, null, null, null], chances: [0, 3, 0, 5, 0, 0] },
    { pattern: [1, 3, null, null], chances: [0, 1, 3, 0, 0, 0] },
    { pattern: [3, 2, null, null], chances: [0, 0, 0, 0, 1, 0] }
  ],
  [
    { pattern: [null, null, null, null], chances: [1, 2, 0, 0, 0, 0] },
    { pattern: [1, null, null, null], chances: [0, 0, 1, 0, 0, 0] },
    { pattern: [null, 1, 2, null], chances: [0, 0, 0, 1, 0, 0] },
    { pattern: [3, null, null, null], chances: [0, 0, 0, 0, 1, 0] }
  ],
  [
    { pattern: [null, null, null, null], chances: [3, 0, 5, 0, 0, 0] },
    { pattern: [null, 2, null, null], chances: [0, 0, 0, 0, 4, 0] },
    { pattern: [null, 4, 3, null], chances: [0, 0, 4, 0, 1, 0] },
    { pattern: [null, 0, null, null], chances: [0, 1, 1, 1, 1, 0] }
  ],
  [
    { pattern: [null, null, null, null], chances: [0, 1, 1, 1, 1, 0] },
    { pattern: [1, null, null, null], chances: [0, 0, 1, 0, 0, 0] },
    { pattern: [3, null, null, null], chances: [0, 0, 0, 0, 1, 0] },
    { pattern: [null, 1, 2, null], chances: [0, 1, 0, 0, 0, 0] },
    { pattern: [null, 3, 4, null], chances: [0, 1, 0, 1, 0, 0] }
  ],
  [
    { pattern: [null, null, null, null], chances: [0, 1, 1, 1, 1, 0] },
    { pattern: [1, null, null, null], chances: [0, 0, 1, 0, 0, 0] },
    { pattern: [3, null, null, null], chances: [0, 0, 0, 0, 1, 0] },
    { pattern: [null, 1, 2, null], chances: [0, 1, 0, 0, 0, 0] },
    { pattern: [null, 3, 4, null], chances: [0, 1, 0, 1, 0, 0] },
    { pattern: [null, 2, null, null], chances: [0, 0, 0, 0, 1, 0] },
    { pattern: [null, 1, null, null], chances: [0, 0, 0, 1, 0, 0] }
  ]
]

// 0 = _
// 1 = ◢
// 2 = ◣
// 3 = ◥
// 4 = ◤
// 5 = []

import { speedRatio } from '../components/Artwork'
import { RGBToString } from './lib/colours'
import { mul2 } from './lib/math'
import { random } from './lib/random'
import { viewport } from './lib/viewport'
import { getCurrentPalette } from './palette'
// import { palette as fullPalette, samplePalette } from './palette'
import { getMatchingRule } from './rules'
import {
  Cell,
  CellKind,
  CellState,
  Neighbours,
  RGB,
  Rule,
  StepChances
} from './types'

const HORIZONTAL_MARGIN_PERCENTAGE = 2.5
const COLOUR_EASING = 50
const FADE_SPEED = 0.01

// export let currentPalette = samplePalette(fullPalette)

interface State {
  cols: number
  rows: number
  verticalMargin: number
  horizontalMargin: number
  cellSize: number
}

export let animationState: State | undefined

export function setDimensions(): void {
  const vp = mul2(viewport(), window.devicePixelRatio)
  const horizontalMargin = (vp[0] * HORIZONTAL_MARGIN_PERCENTAGE) / 100 // 5% margin

  const horizontalSpaceWithinMargins = vp[0] - horizontalMargin * 2
  const verticalSpaceWithinMargins = vp[1] - horizontalMargin * 2
  const withMarginsRatio =
    horizontalSpaceWithinMargins / verticalSpaceWithinMargins
  const cols = withMarginsRatio > 1.2 ? 32 : withMarginsRatio < 0.7 ? 24 : 24
  const rows = Math.round(cols / withMarginsRatio)
  const cellSize = (vp[0] - horizontalMargin * 2) / cols
  console.log(cols, rows)
  const heightTakenUpByCells = rows * cellSize
  const verticalMargin = (vp[1] - heightTakenUpByCells) / 2
  animationState = {
    verticalMargin,
    rows,
    horizontalMargin,
    cellSize,
    cols
  }
}

// const USE_EASING = false
const TRANSITION_SPEED_DIV = 50

function cellAt(grid: Cell[], x: number, y: number): Cell {
  const cell = grid[x + y * animationState!.cols]
  if (
    cell === undefined ||
    y < 0 ||
    x < 0 ||
    x >= animationState!.cols ||
    y >= animationState!.rows
  )
    return { kind: 0, group: null }
  return cell
}

function sampleNextStep(array: StepChances, seed: number): CellKind {
  const options: number[] = []
  array.forEach((element, index) => {
    for (let i = 0; i < element * 10; i++) {
      options.push(index)
    }
  })
  const randomIndex = Math.round(random(seed, options.length - 1))
  return options[randomIndex] as CellKind
}

export function getNextCellKind(
  neighbours: Neighbours,
  seed: number,
  rules: Rule[]
): CellKind {
  const matchingRule = getMatchingRule(neighbours, rules)
  return sampleNextStep(matchingRule.chances, seed)
}

function getNextCell(
  grid: Cell[],
  rules: Rule[],
  x: number,
  y: number,
  seed: number
): Cell {
  const stepLeft = cellAt(grid, x - 1, y)
  const step2Left = cellAt(grid, x - 2, y)
  const stepAbove = cellAt(grid, x, y - 1)
  const stepAboveRight = cellAt(grid, x + 1, y - 1)
  const neighbours: Neighbours = [
    stepLeft.kind,
    stepAbove.kind,
    stepAboveRight.kind,
    step2Left.kind
  ]
  const selectFrom = getNextCellKind(neighbours, seed, rules)
  if (selectFrom === undefined) {
    throw new Error('cannot find next possible cell step')
  }

  return {
    kind: selectFrom,
    group: null
  }
}

export function createGrid(rules: Rule[], seed: number): Cell[] {
  setDimensions()
  const result: Cell[] = []
  let newSeed = seed
  for (let y = 0; y < animationState!.rows; y++) {
    for (let x = 0; x < animationState!.cols; x++) {
      newSeed++
      let nextCell = getNextCell(result, rules, x, y, newSeed)
      result[x + y * animationState!.cols] = nextCell
    }
  }
  return result
}

function getTargetAngle(kind: CellKind): number {
  switch (kind) {
    case 0:
      return 0
    case 1:
      return 0
    case 2:
      return (90 / 360) * Math.PI * 2
    case 3:
      return (-90 / 360) * Math.PI * 2
    case 4:
      return (180 / 360) * Math.PI * 2
    default:
      throw new Error('unknown cellkind, cannot determine getTargetAngle')
  }
}

// function getTargetLengthMultiplier(kind: CellKind): number {
//   if ([0, 2, 4].includes(kind)) return 1.85
//   return 1.22
// }

export function updateAllCellsState(
  grid: Cell[],
  cellState: CellState[],
  maxIndex: number
): void {
  for (let i = 0; i < Math.min(maxIndex, cellState.length); i++) {
    const cell = grid[i]
    updateCellState(cell, cellState[i])
  }
}

function updateCellStateColour(cell: Cell, cellState: CellState) {
  const targetColour = getCellColour(cell.group)
  const currentColour: RGB = cellState.color
  currentColour[0] += (targetColour[0] - currentColour[0]) / COLOUR_EASING / speedRatio()
  currentColour[1] += (targetColour[1] - currentColour[1]) / COLOUR_EASING / speedRatio()
  currentColour[2] += (targetColour[2] - currentColour[2]) / COLOUR_EASING / speedRatio()
}

function updateCellState(cell: Cell, cellState: CellState): void {
  const directionAngle = getTargetAngle(cell.kind) > cellState.angle ? 1 : -1

  if (Math.abs(getTargetAngle(cell.kind) - cellState.angle) > 0.01) {
    cellState.angle += directionAngle / TRANSITION_SPEED_DIV / speedRatio()
  } else {
    cellState.angle = getTargetAngle(cell.kind)
    updateCellStateColour(cell, cellState)
  }
  if (cell.kind === 0) {
    if (cellState.alpha > 0) {
      cellState.alpha -= FADE_SPEED / speedRatio()
    } else {
      cellState.alpha = 0
    }
  } else {
    if (cellState.alpha < 1) {
      cellState.alpha += FADE_SPEED / speedRatio()
    } else {
      cellState.alpha = 1
    }
  }
}

export function drawCells(
  ctx: CanvasRenderingContext2D,
  grid: Cell[],
  cellState: CellState[]
): void {
  ctx.save()
  for (let i = 0; i < cellState.length; i++) {
    const x = i % animationState!.cols
    const y = (i / animationState!.cols) << 0
    drawCell(ctx, x, y, cellState[i], grid[i])
  }
  ctx.restore()
}

function drawCell(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  cellState: CellState,
  cell: Cell
): void {
  ctx.save()

  const { cellSize, horizontalMargin, verticalMargin } = animationState!
  ctx.fillStyle = RGBToString(cellState.color)
  ctx.globalAlpha = Math.max(0, Math.min(cellState.alpha, 1))
  ctx.lineWidth = 1
  ctx.beginPath()
  const r = Math.ceil(cellSize / 2) // cellState.length
  const c = [
    Math.floor(x * cellSize + cellSize / 2 + horizontalMargin),
    Math.floor(y * cellSize + cellSize / 2 + verticalMargin)
  ]

  ctx.translate(c[0], c[1])
  ctx.rotate(cellState.angle)
  ctx.moveTo(+r, -r)
  ctx.lineTo(+r, +r)
  ctx.lineTo(-r, +r)
  ctx.lineTo(+r, -r)

  ctx.fill()
  ctx.restore()
}

function getCellColour(cellGroup: number | null): RGB {
  if (cellGroup === null) return [200, 200, 200]
  const currentPalette = getCurrentPalette()
  return currentPalette[cellGroup % currentPalette.length]
}

export function addGroupNumbers(grid: Cell[]): Cell[] {
  for (let i = 0; i < grid.length; i++) {
    const x = i % animationState!.cols
    const y = (i / animationState!.cols) << 0
    traverseCellToGiveGroup(grid, x, y, i)
  }
  return grid
}

function traverseCellToGiveGroup(
  grid: Cell[],
  x: number,
  y: number,
  newGroupNumber: number
): void {
  const cell = cellAt(grid, x, y)
  if (
    x < 0 ||
    x > animationState!.cols ||
    y < 0 ||
    y > animationState!.rows ||
    cell.group !== null
  ) {
    return
  }

  cell.group = newGroupNumber

  function check(dx: number, dy: number, kind: CellKind) {
    if (cellAt(grid, x + dx, y + dy).kind === kind) {
      traverseCellToGiveGroup(grid, x + dx, y + dy, newGroupNumber)
    }
  }

  switch (cell.kind) {
    case 1:
      check(1, 0, 2)
      check(1, 0, 4)
      check(0, 1, 3)
      check(0, 1, 4)
      break
    case 2:
      check(-1, 1, 1)
      check(-1, 1, 3)
      check(0, 1, 4)
      check(0, 1, 3)
      check(-1, 0, 3)
      check(-1, 0, 1)
      break
    case 3:
      check(1, 0, 4)
      check(1, 0, 2)
      check(0, -1, 2)
      check(0, -1, 1)
      break
    case 4:
      check(0, -1, 2)
      check(0, -1, 1)
      check(-1, 0, 1)
      check(-1, 0, 3)
      break
  }
}

/* global BigInt */
const U32_MASK64 = BigInt(2 ** 32 - 1)
const _32n = BigInt(32)
function fromBig(n, le = false) {
  if (le)
    return { h: Number(n & U32_MASK64), l: Number((n >> _32n) & U32_MASK64) }
  return {
    h: Number((n >> _32n) & U32_MASK64) | 0,
    l: Number(n & U32_MASK64) | 0
  }
}
function split(lst, le = false) {
  let Ah = new Uint32Array(lst.length)
  let Al = new Uint32Array(lst.length)
  for (let i = 0; i < lst.length; i++) {
    const { h, l } = fromBig(lst[i], le)
    ;[Ah[i], Al[i]] = [h, l]
  }
  return [Ah, Al]
}
const toBig = (h, l) => (BigInt(h >>> 0) << _32n) | BigInt(l >>> 0)
const shrSH = (h, l, s) => h >>> s
const shrSL = (h, l, s) => (h << (32 - s)) | (l >>> s)
const rotrSH = (h, l, s) => (h >>> s) | (l << (32 - s))
const rotrSL = (h, l, s) => (h << (32 - s)) | (l >>> s)
const rotrBH = (h, l, s) => (h << (64 - s)) | (l >>> (s - 32))
const rotrBL = (h, l, s) => (h >>> (s - 32)) | (l << (64 - s))
const rotr32H = (h, l) => l
const rotr32L = (h, l) => h
const rotlSH = (h, l, s) => (h << s) | (l >>> (32 - s))
const rotlSL = (h, l, s) => (l << s) | (h >>> (32 - s))
const rotlBH = (h, l, s) => (l << (s - 32)) | (h >>> (64 - s))
const rotlBL = (h, l, s) => (h << (s - 32)) | (l >>> (64 - s))

function add(Ah, Al, Bh, Bl) {
  const l = (Al >>> 0) + (Bl >>> 0)
  return { h: (Ah + Bh + ((l / 2 ** 32) | 0)) | 0, l: l | 0 }
}
const add3L = (Al, Bl, Cl) => (Al >>> 0) + (Bl >>> 0) + (Cl >>> 0)
const add3H = (low, Ah, Bh, Ch) => (Ah + Bh + Ch + ((low / 2 ** 32) | 0)) | 0
const add4L = (Al, Bl, Cl, Dl) =>
  (Al >>> 0) + (Bl >>> 0) + (Cl >>> 0) + (Dl >>> 0)
const add4H = (low, Ah, Bh, Ch, Dh) =>
  (Ah + Bh + Ch + Dh + ((low / 2 ** 32) | 0)) | 0
const add5L = (Al, Bl, Cl, Dl, El) =>
  (Al >>> 0) + (Bl >>> 0) + (Cl >>> 0) + (Dl >>> 0) + (El >>> 0)
const add5H = (low, Ah, Bh, Ch, Dh, Eh) =>
  (Ah + Bh + Ch + Dh + Eh + ((low / 2 ** 32) | 0)) | 0
const u64 = {
  fromBig,
  split,
  toBig,
  shrSH,
  shrSL,
  rotrSH,
  rotrSL,
  rotrBH,
  rotrBL,
  rotr32H,
  rotr32L,
  rotlSH,
  rotlSL,
  rotlBH,
  rotlBL,
  add,
  add3L,
  add3H,
  add4L,
  add4H,
  add5H,
  add5L
}

const _u64 = u64

const u32 = (arr) =>
  new Uint32Array(arr.buffer, arr.byteOffset, Math.floor(arr.byteLength / 4))

function utf8ToBytes(str) {
  return new TextEncoder().encode(str)
}

function toBytes(data) {
  if (typeof data === 'string') data = utf8ToBytes(data)
  return data
}

class Hash {
  clone() {
    return this._cloneInto()
  }
}
function wrapConstructor(hashConstructor) {
  const hashC = (message) => hashConstructor().update(toBytes(message)).digest()
  const tmp = hashConstructor()
  hashC.outputLen = tmp.outputLen
  hashC.blockLen = tmp.blockLen
  hashC.create = () => hashConstructor()
  return hashC
}
const [SHA3_PI, SHA3_ROTL, _SHA3_IOTA] = [[], [], []]
const _0n = BigInt(0)
const _1n = BigInt(1)
const _2n = BigInt(2)
const _7n = BigInt(7)
const _256n = BigInt(256)
const _0x71n = BigInt(0x71)
for (let round = 0, R = _1n, x = 1, y = 0; round < 24; round++) {
  ;[x, y] = [y, (2 * x + 3 * y) % 5]
  SHA3_PI.push(2 * (5 * y + x))
  SHA3_ROTL.push((((round + 1) * (round + 2)) / 2) % 64)
  let t = _0n
  for (let j = 0; j < 7; j++) {
    R = ((R << _1n) ^ ((R >> _7n) * _0x71n)) % _256n
    if (R & _2n) t ^= _1n << ((_1n << BigInt(j)) - _1n)
  }
  _SHA3_IOTA.push(t)
}
const [SHA3_IOTA_H, SHA3_IOTA_L] = _u64.split(_SHA3_IOTA, true)
const rotlH = (h, l, s) =>
  s > 32 ? _u64.rotlBH(h, l, s) : _u64.rotlSH(h, l, s)
const rotlL = (h, l, s) =>
  s > 32 ? _u64.rotlBL(h, l, s) : _u64.rotlSL(h, l, s)
function keccakP(s, rounds = 24) {
  const B = new Uint32Array(5 * 2)
  for (let round = 24 - rounds; round < 24; round++) {
    for (let x = 0; x < 10; x++)
      B[x] = s[x] ^ s[x + 10] ^ s[x + 20] ^ s[x + 30] ^ s[x + 40]
    for (let x = 0; x < 10; x += 2) {
      const idx1 = (x + 8) % 10
      const idx0 = (x + 2) % 10
      const B0 = B[idx0]
      const B1 = B[idx0 + 1]
      const Th = rotlH(B0, B1, 1) ^ B[idx1]
      const Tl = rotlL(B0, B1, 1) ^ B[idx1 + 1]
      for (let y = 0; y < 50; y += 10) {
        s[x + y] ^= Th
        s[x + y + 1] ^= Tl
      }
    }
    let curH = s[2]
    let curL = s[3]
    for (let t = 0; t < 24; t++) {
      const shift = SHA3_ROTL[t]
      const Th = rotlH(curH, curL, shift)
      const Tl = rotlL(curH, curL, shift)
      const PI = SHA3_PI[t]
      curH = s[PI]
      curL = s[PI + 1]
      s[PI] = Th
      s[PI + 1] = Tl
    }
    for (let y = 0; y < 50; y += 10) {
      for (let x = 0; x < 10; x++) B[x] = s[y + x]
      for (let x = 0; x < 10; x++)
        s[y + x] ^= ~B[(x + 2) % 10] & B[(x + 4) % 10]
    }
    s[0] ^= SHA3_IOTA_H[round]
    s[1] ^= SHA3_IOTA_L[round]
  }
  B.fill(0)
}
class Keccak extends Hash {
  constructor(blockLen, suffix, outputLen, enableXOF = false, rounds = 24) {
    super()
    this.blockLen = blockLen
    this.suffix = suffix
    this.outputLen = outputLen
    this.enableXOF = enableXOF
    this.rounds = rounds
    this.pos = 0
    this.posOut = 0
    this.finished = false
    this.destroyed = false
    this.state = new Uint8Array(200)
    this.state32 = u32(this.state)
  }
  keccak() {
    keccakP(this.state32, this.rounds)
    this.posOut = 0
    this.pos = 0
  }
  update(data) {
    const { blockLen, state } = this
    data = toBytes(data)
    const len = data.length
    for (let pos = 0; pos < len; ) {
      const take = Math.min(blockLen - this.pos, len - pos)
      for (let i = 0; i < take; i++) state[this.pos++] ^= data[pos++]
      if (this.pos === blockLen) this.keccak()
    }
    return this
  }
  finish() {
    if (this.finished) return
    this.finished = true
    const { state, suffix, pos, blockLen } = this
    state[pos] ^= suffix
    if ((suffix & 0x80) !== 0 && pos === blockLen - 1) this.keccak()
    state[blockLen - 1] ^= 0x80
    this.keccak()
  }
  writeInto(out) {
    this.finish()
    const bufferOut = this.state
    const { blockLen } = this
    for (let pos = 0, len = out.length; pos < len; ) {
      if (this.posOut >= blockLen) this.keccak()
      const take = Math.min(blockLen - this.posOut, len - pos)
      out.set(bufferOut.subarray(this.posOut, this.posOut + take), pos)
      this.posOut += take
      pos += take
    }
    return out
  }
  xofInto(out) {
    return this.writeInto(out)
  }
  xof(bytes) {
    return this.xofInto(new Uint8Array(bytes))
  }
  digestInto(out) {
    if (this.finished) throw new Error('digest() was already called')
    this.writeInto(out)
    this.destroy()
    return out
  }
  digest() {
    return this.digestInto(new Uint8Array(this.outputLen))
  }
  destroy() {
    this.destroyed = true
    this.state.fill(0)
  }
  _cloneInto(to) {
    const { blockLen, suffix, outputLen, rounds, enableXOF } = this
    to || (to = new Keccak(blockLen, suffix, outputLen, enableXOF, rounds))
    to.state32.set(this.state32)
    to.pos = this.pos
    to.posOut = this.posOut
    to.finished = this.finished
    to.rounds = rounds
    to.suffix = suffix
    to.outputLen = outputLen
    to.enableXOF = enableXOF
    to.destroyed = this.destroyed
    return to
  }
}
const gen = (suffix, blockLen, outputLen) =>
  wrapConstructor(() => new Keccak(blockLen, suffix, outputLen))

const keccak_256 = gen(0x01, 136, 256 / 8)

function _getBytes(value, name, copy) {
  if (value instanceof Uint8Array) {
    if (copy) {
      return new Uint8Array(value)
    }
    return value
  }
  if (typeof value === 'string' && value.match(/^0x([0-9a-f][0-9a-f])*$/i)) {
    const result = new Uint8Array((value.length - 2) / 2)
    let offset = 2
    for (let i = 0; i < result.length; i++) {
      result[i] = parseInt(value.substring(offset, offset + 2), 16)
      offset += 2
    }
    return result
  }
}

function data_getBytes(value, name) {
  return _getBytes(value, name, false)
}

const HexCharacters = '0123456789abcdef'
function data_hexlify(data) {
  const bytes = data_getBytes(data)
  let result = '0x'
  for (let i = 0; i < bytes.length; i++) {
    const v = bytes[i]
    result += HexCharacters[(v & 0xf0) >> 4] + HexCharacters[v & 0x0f]
  }
  return result
}
function concat(datas) {
  return '0x' + datas.map((d) => data_hexlify(d).substring(2)).join('')
}

function zeroPad(data, length, left) {
  const bytes = data_getBytes(data)
  const result = new Uint8Array(length)
  result.fill(0)
  if (left) {
    result.set(bytes, length - bytes.length)
  } else {
    result.set(bytes, 0)
  }
  return data_hexlify(result)
}

function zeroPadValue(data, length) {
  return zeroPad(data, length, true)
}

function zeroPadBytes(data, length) {
  return zeroPad(data, length, false)
}

const _keccak256 = function (data) {
  return keccak_256(data)
}
let __keccak256 = _keccak256

function keccak256(_data) {
  const data = data_getBytes(_data, 'data')
  return data_hexlify(__keccak256(data))
}

function toUtf8Bytes(str, form) {
  if (form != null) {
    str = str.normalize(form)
  }
  let result = []
  for (let i = 0; i < str.length; i++) {
    const c = str.charCodeAt(i)
    if (c < 0x80) {
      result.push(c)
    } else if (c < 0x800) {
      result.push((c >> 6) | 0xc0)
      result.push((c & 0x3f) | 0x80)
    } else if ((c & 0xfc00) == 0xd800) {
      i++
      const c2 = str.charCodeAt(i)
      const pair = 0x10000 + ((c & 0x03ff) << 10) + (c2 & 0x03ff)
      result.push((pair >> 18) | 0xf0)
      result.push(((pair >> 12) & 0x3f) | 0x80)
      result.push(((pair >> 6) & 0x3f) | 0x80)
      result.push((pair & 0x3f) | 0x80)
    } else {
      result.push((c >> 12) | 0xe0)
      result.push(((c >> 6) & 0x3f) | 0x80)
      result.push((c & 0x3f) | 0x80)
    }
  }
  return new Uint8Array(result)
}

const maths_BN_0 = BigInt(0)
const BN_1 = BigInt(1)

function toTwos(_value, _width) {
  let value = getBigInt(_value, 'value')
  const width = BigInt(getNumber(_width, 'width'))
  if (value < maths_BN_0) {
    value = -value
    const mask = (BN_1 << width) - BN_1
    return (~value & mask) + BN_1
  }
  return value
}

function getBigInt(value, name) {
  switch (typeof value) {
    case 'bigint':
      return value
    case 'number':
      return BigInt(value)
    case 'string':
      if (value[0] === '-' && value[1] !== '-') {
        return -BigInt(value.substring(1))
      }
      return BigInt(value)
  }
}

function getUint(value, name) {
  const result = getBigInt(value, name)
  return result
}

function getNumber(value, name) {
  switch (typeof value) {
    case 'bigint':
      return Number(value)
    case 'number':
      return value
    case 'string':
      return getNumber(BigInt(value), name)
  }
}

function toBeArray(_value) {
  const value = getUint(_value, 'value')
  if (value === maths_BN_0) {
    return new Uint8Array([])
  }
  let hex = value.toString(16)
  if (hex.length % 2) {
    hex = '0' + hex
  }
  const result = new Uint8Array(hex.length / 2)
  for (let i = 0; i < result.length; i++) {
    const offset = i * 2
    result[i] = parseInt(hex.substring(offset, offset + 2), 16)
  }
  return result
}

const regexBytes = new RegExp('^bytes([0-9]+)$')
const regexNumber = new RegExp('^(u?int)([0-9]*)$')
const regexArray = new RegExp('^(.*)\\[([0-9]*)\\]$')
function _pack(type, value, isArray) {
  switch (type) {
    case 'string':
      return toUtf8Bytes(value)
    case 'bytes':
      return data_getBytes(value)
  }
  let match = type.match(regexNumber)
  if (match) {
    let signed = match[1] === 'int'
    let size = parseInt(match[2] || '256')
    if (isArray) {
      size = 256
    }
    if (signed) {
      value = toTwos(value, size)
    }
    return data_getBytes(zeroPadValue(toBeArray(value), size / 8))
  }
  match = type.match(regexBytes)
  if (match) {
    const size = parseInt(match[1])
    if (isArray) {
      return data_getBytes(zeroPadBytes(value, 32))
    }
    return value
  }
  match = type.match(regexArray)
  if (match && Array.isArray(value)) {
    const baseType = match[1]
    const result = []
    value.forEach(function (value) {
      result.push(_pack(baseType, value, true))
    })
    return data_getBytes(concat(result))
  }
}

function solidityPacked(types, values) {
  const tight = []
  types.forEach(function (type, index) {
    tight.push(_pack(type, values[index]))
  })
  return data_hexlify(concat(tight))
}

export default function solidityPackedKeccak256(types, values) {
  return keccak256(solidityPacked(types, values))
}

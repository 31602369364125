import solidityPackedKeccak256 from './solidityPackedKeccak256'

export function testKeccakLib() {
  const testValue = randomUint8(110112)
  if (testValue !== 19) throw new Error('incorrect keccak')
}

export function randomUint8(seed: number) {
  const packedSeed = solidityPackedKeccak256(['uint256'], [seed])
  const bigInt256 = BigInt(packedSeed)
  const bigInt8 = BigInt.asUintN(8, bigInt256)
  return parseInt(bigInt8.toString())
}

export function random(seed: number, max: number): number {
  // returns a number from 0 - max (inclusive!)
  return ((randomUint8(seed << 0) * (max + 1)) / 256) << 0
}

export function maybe(seed: number, chance = 0.5) {
  return random(seed, 1000) < chance * 1000
}

export function sample<T>(seed: number, values: T[]): T {
  const randomIndex = random(seed, values.length - 1)
  return values[randomIndex]
}

export function all<T>(items: T[], value: T): boolean {
  for (let i = 0; i < items.length; i++) {
    if (items[i] !== value) return false
  }
  return true
}

export function shuffle<T>(input: T[]): T[] {
  const array: T[] = [...input]
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

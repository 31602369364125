import { CellKind, Pattern, Rule, StepChances } from '../types'
import { PRESET_RULES } from './preset_rules'
import { all, maybe, random, sample } from './random'

export function getRandomRules(): Rule[] {
  if (Math.random() > 0.75) return getPredefinedRules()
  let seed = (0xfffffff * Math.random()) << 0
  console.log(seed)
  const result: Rule[] = [
    {
      pattern: [null, null, null, null],
      chances: randomRuleChances(seed * 0x3213)
    }
  ]

  if (Math.random() > 0.5) {
    result.push({ pattern: [1, null, null, null], chances: [0, 0, 1, 0, 0, 0] })
    result.push({ pattern: [3, null, null, null], chances: [0, 0, 0, 0, 1, 0] })
  }

  const extraRuleCount = random(seed, 10) + 1
  const startIndex = Math.random() > 0.6 ? 0 : 1
  for (let i = startIndex; i < extraRuleCount; i++) {
    const extraRule: Rule = {
      pattern: randomRulePattern(seed * 0x221 + i),
      chances: randomRuleChances(seed * 0x321 + i)
    }
    result.push(extraRule)
  }
  return result
}

export function mutateRules(oldRules: Rule[]): Rule[] {
  return oldRules
}

function randomRulePattern(seed: number): Pattern {
  const result: Pattern = [null, null, null, null]
  for (let i = 0; i < 4; i++) {
    result[i] = maybe(seed * 0x34 + i, 0.25)
      ? ((random(seed * i, 3) + 1) as CellKind)
      : null
  }
  return result
}

function randomRuleChances(seed: number): StepChances {
  const result: StepChances = [0, 0, 0, 0, 0, 0]
  const values = [0, 1, 10, 100] // , 3, 5, 10]
  for (let i = 0; i <= 4; i++) {
    result[i] = sample(seed * 0x310 + i, values)
  }
  if (all(result, 0)) {
    const index = random(seed, 2) + 1
    result[index] = 1
  }
  return result
}

function getPredefinedRules(): Rule[] {
  const index = (Math.random() * PRESET_RULES.length) << 0
  console.log('sample rule', index)
  return PRESET_RULES[index]
}

import { Neighbours, Rule, RuleWithScore } from './types'

export function getMatchingRule(neighbours: Neighbours, rules: Rule[]): Rule {
  const matchingRules: RuleWithScore[] = []

  for (let i = 0; i < rules.length; i++) {
    const rule = rules[i]
    let digitMatchCount = 0
    let score = 0
    for (let neighbour = 0; neighbour < neighbours.length; neighbour++) {
      if (
        rule.pattern[neighbour] === null ||
        rule.pattern[neighbour] === neighbours[neighbour]
      ) {
        if (rule.pattern[neighbour] !== null) {
          score += 1
        }
        digitMatchCount += 1
      }
    }
    if (digitMatchCount === neighbours.length) {
      matchingRules.push({ rule, score })
    }
  }
  const highestScoring = matchingRules.sort((a, b) =>
    a.score < b.score ? 1 : -1
  )
  return highestScoring[0].rule
}
